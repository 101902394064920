const airtable = require('./schema/airtable');
const telegram = require('./schema/telegram');
const sheet = require('./schema/sheet');
const ow = require('./schema/ow');
const dribbble = require('./schema/dribbble');
const contentful = require('./schema/contentful');
const linkPreview = require('./schema/linkPreview');
const typeform = require('./schema/typeform');
const xml2json = require('./schema/xml2json');
const twilio = require('./schema/twilio');
const uptime = require('./schema/uptime');
const mailgun = require('./schema/mailgun');
const instagram = require('./schema/instagram');
const cloudinary = require('./schema/cloudinary');
const ghost = require('./schema/ghost');
const ga = require('./schema/ga');
const medium = require('./schema/medium');
const github = require('./schema/github');
const twitter = require('./schema/twitter');
const slack = require('./schema/slack');
const algolia = require('./schema/algolia');
const webflow = require('./schema/webflow');
const cx = require('./schema/cx');
const read = require('./schema/read');
const gr = require('./schema/gr');
const yt = require('./schema/yt');
const ep = require('./schema/ep');
const calendly = require('./schema/calendly');
const mailchimp = require('./schema/mailchimp');
const spotify = require('./schema/spotify');
const WordPress = require('./schema/wordpress');
const Pipedrive = require('./schema/pipedrive');
const Vimeo = require('./schema/vimeo');
const Pocket = require('./schema/pocket');
const StripeAPI = require('./schema/stripe');
const FaunaDB = require('./schema/fauna');
const AWSS3 = require('./schema/s3');
const ZohoMail = require('./schema/zohomail');
const Calendar = require('./schema/calendar');
const Unsplash = require('./schema/unsplash');
const SmartSheet = require('./schema/smartsheet');
const Drive = require('./schema/drive');
const Bigquery = require('./schema/bigquery');
const Bigtable = require('./schema/bigtable');
const Webmaster = require('./schema/webmaster');
const Netlify = require('./schema/netlify');
const Gumroad = require('./schema/gumroad');
const fbsdk = require('./schema/fbsdk');
const supabase = require('./schema/supabase');
const notion = require('./schema/notion');
const emailVerify = require('./schema/email-verify');
const html2pdf = require('./schema/pdf');
const ScreenShot = require('./schema/screen');
const LookupAPI = require('./schema/lookup');
const Mailerlite = require('./schema/mailerlite');
const Snipcart = require('./schema/snipcart');
const ZohoSheet = require('./schema/zohosheet');
const GoogleFit = require('./schema/fit');
const Monday = require('./schema/monday');
const FbPages = require('./schema/fbpages');
const HubSpot = require('./schema/hubspot');
const Shopify = require('./schema/shopify');
const csv2json = require('./schema/csv2json');
const gTrends = require('./schema/gTrends');
const databaseMongoApi = require('./schema/databaseMongoApi');
const databasePostgresApi = require('./schema/databasePostgresApi');
const nForms = require('./schema/nForms');
const Enrich = require('./schema/enrich');
const chatGPT = require('./schema/chatgpt');
const quickBooks = require('./schema/quickbooks');

exports.apiJsonDocs = function (username, appId, token) {
  try {
    let schemaObj = airtable.obj(username, appId, token);

    if (appId === 'airtable') schemaObj = airtable.obj(username, appId, token);
    if (appId === 'telegram') schemaObj = telegram.obj(username, appId, token);
    if (appId === 'google_sheets') schemaObj = sheet.obj(username, appId, token);
    if (appId === 'ow') schemaObj = ow.obj(username, appId, token);
    if (appId === 'dribbble') schemaObj = dribbble.obj(username, appId, token);
    if (appId === 'contentful') schemaObj = contentful.obj(username, appId, token);
    if (appId === 'link_preview') schemaObj = linkPreview.obj(username, appId, token);
    if (appId === 'typeform') schemaObj = typeform.obj(username, appId, token);
    if (appId === 'xml_to_json') schemaObj = xml2json.obj(username, appId, token);
    if (appId === 'twilio') schemaObj = twilio.obj(username, appId, token);
    if (appId === 'uptime') schemaObj = uptime.obj(username, appId, token);
    if (appId === 'mailgun') schemaObj = mailgun.obj(username, appId, token);
    if (appId === 'instagram') schemaObj = instagram.obj(username, appId, token);
    if (appId === 'cloudinary') schemaObj = cloudinary.obj(username, appId, token);
    if (appId === 'ghost') schemaObj = ghost.obj(username, appId, token);
    if (appId === 'ga') schemaObj = ga.obj(username, appId, token);
    if (appId === 'medium') schemaObj = medium.obj(username, appId, token);
    if (appId === 'github') schemaObj = github.obj(username, appId, token);
    if (appId === 'twitter') schemaObj = twitter.obj(username, appId, token);
    if (appId === 'slack') schemaObj = slack.obj(username, appId, token);
    if (appId === 'algolia') schemaObj = algolia.obj(username, appId, token);
    if (appId === 'webflow') schemaObj = webflow.obj(username, appId, token);
    if (appId === 'cx') schemaObj = cx.obj(username, appId, token);
    if (appId === 'read') schemaObj = read.obj(username, appId, token);
    if (appId === 'gr') schemaObj = gr.obj(username, appId, token);
    if (appId === 'yt') schemaObj = yt.obj(username, appId, token);
    if (appId === 'ep') schemaObj = ep.obj(username, appId, token);
    if (appId === 'calendly') schemaObj = calendly.obj(username, appId, token);
    if (appId === 'mailchimp') schemaObj = mailchimp.obj(username, appId, token);
    if (appId === 'spotify') schemaObj = spotify.obj(username, appId, token);
    if (appId === 'wordpress') schemaObj = WordPress.obj(username, appId, token);
    if (appId === 'pipedrive') schemaObj = Pipedrive.obj(username, appId, token);
    if (appId === 'vimeo') schemaObj = Vimeo.obj(username, appId, token);
    if (appId === 'pocket') schemaObj = Pocket.obj(username, appId, token);
    if (appId === 'stripe') schemaObj = StripeAPI.obj(username, appId, token);
    if (appId === 'fauna') schemaObj = FaunaDB.obj(username, appId, token);
    if (appId === 's3') schemaObj = AWSS3.obj(username, appId, token);
    if (appId === 'zohomail') schemaObj = ZohoMail.obj(username, appId, token);
    if (appId === 'calendar') schemaObj = Calendar.obj(username, appId, token);
    if (appId === 'unsplash') schemaObj = Unsplash.obj(username, appId, token);
    if (appId === 'smartsheet') schemaObj = SmartSheet.obj(username, appId, token);
    if (appId === 'drive') schemaObj = Drive.obj(username, appId, token);
    if (appId === 'webmaster') schemaObj = Webmaster.obj(username, appId, token);
    if (appId === 'netlify') schemaObj = Netlify.obj(username, appId, token);
    if (appId === 'gumroad') schemaObj = Gumroad.obj(username, appId, token);
    if (appId === 'fbsdk') schemaObj = fbsdk.obj(username, appId, token);
    if (appId === 'supabase') schemaObj = supabase.obj(username, appId, token);
    if (appId === 'notion') schemaObj = notion.obj(username, appId, token);
    if (appId === 'email-verify') schemaObj = emailVerify.obj(username, appId, token);
    if (appId === 'pdf') schemaObj = html2pdf.obj(username, appId, token);
    if (appId === 'screen') schemaObj = ScreenShot.obj(username, appId, token);
    if (appId === 'lookup') schemaObj = LookupAPI.obj(username, appId, token);
    if (appId === 'mailerlite') schemaObj = Mailerlite.obj(username, appId, token);
    if (appId === 'snipcart') schemaObj = Snipcart.obj(username, appId, token);
    if (appId === 'zohosheet') schemaObj = ZohoSheet.obj(username, appId, token);
    if (appId === 'fit') schemaObj = GoogleFit.obj(username, appId, token);
    if (appId === 'mondaydotcom') schemaObj = Monday.obj(username, appId, token);
    if (appId === 'fbpages') schemaObj = FbPages.obj(username, appId, token);
    if (appId === 'hubspot') schemaObj = HubSpot.obj(username, appId, token);
    if (appId === 'shopify') schemaObj = Shopify.obj(username, appId, token);
    if (appId === 'csv2json') schemaObj = csv2json.obj(username, appId, token);
    if (appId === 'gTrends') schemaObj = gTrends.obj(username, appId, token);
    if (appId === 'databaseMongoApi') schemaObj = databaseMongoApi.obj(username, appId, token);
    if (appId === 'databasePostgresApi')
      schemaObj = databasePostgresApi.obj(username, appId, token);
    if (appId === 'nForms') schemaObj = nForms.obj(username, appId, token);
    if (appId === 'enrich') schemaObj = Enrich.obj(username, appId, token);
    if (appId === 'chatgpt') schemaObj = chatGPT.obj(username, appId, token);
    if (appId === 'quickbooks') schemaObj = quickBooks.obj(username, appId, token);
    if (appId === 'bigquery') schemaObj = Bigquery.obj(username, appId, token);
    if (appId === 'bigtable') schemaObj = Bigtable.obj(username, appId, token);
    return schemaObj;
  } catch (error) {
    return {};
  }
};
