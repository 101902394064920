import React, { useState } from 'react';

import { Row, Col, Modal, Button, Tabs, Input, Form, Alert, message, Collapse, Select } from 'antd';
import axios from 'axios';
import { CopyOutlined } from '@ant-design/icons';
import { BiSupport } from 'react-icons/bi';
import { CgRead } from 'react-icons/cg';
import { AiOutlinePlaySquare } from 'react-icons/ai';
import { RiMouseLine, RiArrowRightLine } from 'react-icons/ri';
import { SiAirtable, SiGooglesheets } from 'react-icons/si';
import 'react-reflex/styles.css';
import CodeBlock from './codeBlocks';
import Editor from '@monaco-editor/react';
import {
  getAppName,
  getDocLink,
  getQueryParam,
  getBodyParam,
  getFormData,
} from '../../config/custom/functions';

import CodeSnippet from './codeSnippet';
import {
  Writer,
  TemplateEditor,
  CopyWriter,
  QuestionWriter,
  SwissKnife,
  BrainStormingPartner,
  AntiFlashCardWriter,
  Translator,
  Programmer,
  Tutor,
  UserText,
} from './templates';

import '../../styles/playground.scss';

const { TabPane } = Tabs;

function getRequestColor(reqType) {
  let colorName = '';

  if (reqType === 'get') colorName = '#61affe';
  if (reqType === 'post') colorName = '#49cc90';
  if (reqType === 'put') colorName = '#fca130';
  if (reqType === 'delete') colorName = '#f93e3e';
  if (reqType === 'patch') colorName = '#fca130';

  return colorName;
}

const TabTitle = ({ title, obj }) => (
  <div className='method-style'>
    <div className='typeName' style={{ backgroundColor: getRequestColor(obj.reqType) }}>
      {obj.reqType.toUpperCase()}
    </div>
    {title}
  </div>
);

const templates = [
  { label: 'Writer', value: 'writer' },
  { label: 'Editor', value: 'editor' },
  { label: 'Copywriter', value: 'copywriter' },
  { label: 'Question Writer', value: 'questionWriter' },
  { label: 'Swiss Knife Assistant', value: 'swissKnifeAssistant' },
  { label: 'Brainstorming Partner', value: 'brainStormingPartner' },
  { label: 'Anki Flashcard Writer', value: 'ankiFlashCardWriter' },
  { label: 'Translate', value: 'translate' },
  { label: 'Programmer', value: 'programmer' },
  { label: 'Tutor', value: 'tutor' },
];

const APIPlayground = ({ docsJSON, appId, apiMetaData, token, baseURLForEndpoint }) => {
  const { apis_info } = apiMetaData;
  const [visible, setVisible] = useState(false);
  const [makingHTTPcall, setMakingHTTPcall] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [reqStatus, setReqStatus] = useState('');
  const [pathParamId, setPathParamId] = useState('');
  const [reqStatusType, setReqStatusType] = useState('success');
  const [responseResult, setResponseResult] = useState({});
  const [apiMetadata, setApiMetadata] = useState(docsJSON);
  const [bodyPayload, setBodyPayload] = useState(null);
  const [endpointURL, setEndpointURL] = useState('');
  const [requestMethod, setRequestMethod] = useState('GET');
  const [collapsed, setCollapsed] = useState(false);
  const [activeKeyResponsePane, setActiveKeyResponsePane] = useState('5');

  const [template, setTemplate] = useState('');

  const [formMap, setFormMap] = useState({});
  const [promptBodyData, setPromptBodyData] = useState('');

  const handlePromptDataChange = (formData) => {
    setFormMap({ ...formData });
  };
  const handlePromptBodyChange = (bodyData) => {
    setPromptBodyData(bodyData);
  };

  const componentHashMap = {
    writer: <Writer handleChange={handlePromptDataChange} />,
    editor: <TemplateEditor handleChange={handlePromptDataChange} />,
    copywriter: <CopyWriter handleChange={handlePromptDataChange} />,
    questionWriter: <QuestionWriter handleChange={handlePromptDataChange} />,
    swissKnifeAssistant: <SwissKnife handleChange={handlePromptDataChange} />,
    brainStormingPartner: <BrainStormingPartner handleChange={handlePromptDataChange} />,
    ankiFlashCardWriter: <AntiFlashCardWriter handleChange={handlePromptDataChange} />,
    translate: <Translator handleChange={handlePromptDataChange} />,
    programmer: <Programmer handleChange={handlePromptDataChange} />,
    tutor: <Tutor handleChange={handlePromptDataChange} />,
    add: <UserText handleChange={handlePromptBodyChange} />,
  };
  const { Panel } = Collapse;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onFinish = (values) => {
    try {
      setMakingHTTPcall(true);
      const { reqMeta, bodyObj, pathParam, ...fromQueryParam } = values;
      const bodyPayloadLen = Object.keys(bodyObj).length;
      let [url, type] = reqMeta;
      const queryParamList = [];
      setEndpointURL(url);
      let fullURL = '';

      let optionParamQuery = {};

      Object.keys(fromQueryParam).forEach((key) => {
        if (fromQueryParam[key] !== undefined && fromQueryParam[key] !== '') {
          queryParamList.push(`${key}=${fromQueryParam[key]}`);
          optionParamQuery[key] = fromQueryParam[key];
        }
      });

      if (queryParamList.length > 0) {
        const paramStr = queryParamList.join('&');
        setEndpointURL(`${url}?${paramStr}`);
        fullURL = `${url}?${paramStr}`;
      } else {
        setEndpointURL(url);
        fullURL = url;
      }

      if (pathParam !== undefined && pathParamId !== undefined) {
        url = url.replace(`{${pathParamId}}`, pathParam);
        setEndpointURL(url);
        fullURL = url;
      }
      let options = {
        method: type,
        url,
        headers: { 'content-type': 'application/json' },
      };

      if (appId === 'xml_to_json') {
        options.headers = { 'content-type': 'application/xml' };
      }

      options.params = optionParamQuery;

      if (bodyPayload !== null && bodyPayload !== undefined) {
        options.data = JSON.parse(JSON.stringify(bodyPayload.replace(/(\r\n|\n|\r)/gm, '')));
      }

      if (bodyPayloadLen !== 0 && bodyPayload === null) {
        setActiveTab('3');
        setMakingHTTPcall(false);
        message.info('Edit — Body param is required');
        return;
      }

      if (appId === 'chatgpt' && reqMeta[1] === 'get') {
        let formattedPrompt = '';
        console.log(formMap, '-------------');
        Object.keys(formMap).forEach((form) => {
          if (formMap[form]) {
            formattedPrompt += form + ': ' + formMap[form] + '\n';
          }
        });
        setMakingHTTPcall(false);
        setActiveTab('4');
        formattedPrompt += promptBodyData;
        setResponseResult(formattedPrompt);
        setReqStatusType('success');
        setReqStatus('Prompt generation successful');
        setActiveKeyResponsePane('4');
        return;
      }

      if (appId === 'pdf' || (appId === 'screen' && endpointURL.indexOf('screenshots') !== -1)) {
        const link = document.createElement('a');
        link.href = fullURL;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
        setMakingHTTPcall(false);
        setReqStatusType('success');
        setReqStatus('API request successful');
      } else {
        axios(options)
          .then((resultData) => {
            setResponseResult(JSON.stringify(resultData.data, null, 2));
            setMakingHTTPcall(false);
            setActiveTab('4');
            setReqStatusType('success');
            setReqStatus('API request successful');
            setActiveKeyResponsePane('4');
          })
          .catch((error) => {
            let info = '';
            try {
              info = error.response.data.info;
            } catch (error) {
              info = error.message;
            }

            if (info === undefined) info = 'Maybe input are not valid';

            setMakingHTTPcall(false);
            setActiveTab('4');
            setResponseResult(JSON.stringify({ message: info }, null, 2));
            setReqStatusType('error');
            setReqStatus('API request faild');
            setActiveKeyResponsePane('4');
          });
      }
    } catch (error) {
      setMakingHTTPcall(false);
      message.error(error.message);
    }
  };

  const changeTab = (activeKey) => {
    setActiveTab(activeKey);
  };

  const changeResponseTab = (activeKey) => {
    setActiveKeyResponsePane(activeKey);
  };

  const triggerCopy = (endpointURL) => {
    const textField = document.createElement('textarea');
    textField.innerText = endpointURL;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    message.success('copied');
  };

  function handleEditorChange(value, event) {
    setBodyPayload(value);
  }

  const ModalHead = () => (
    <div className='modal-head'>
      <h3>
        <img src={`/logos/${appId}.png`} alt='' height='26px' />{' '}
        <div>{getAppName(appId)} API Endpoints & Playground</div>
      </h3>
      <div className='modal-head-links'>
        <ul>
          {appId === 'airtable' && (
            <li>
              <a href={`https://airtable.com/${apis_info.base_id}`} target='_blank'>
                <SiAirtable /> <span>Airtable base</span>
              </a>
            </li>
          )}
          {appId === 'google_sheets' && (
            <li>
              <a
                href={`https://docs.google.com/spreadsheets/d/${apis_info.sheet_id}`}
                target='_blank'
              >
                <SiGooglesheets /> <span>Google Spreadsheet</span>
              </a>
            </li>
          )}

          <li>
            <a href='https://calendly.com/basiliskan/30min' target='_blank'>
              <BiSupport /> <span>Need help to integrate?</span>
            </a>
          </li>
          <li>
            <a href={getDocLink(appId)} target='_blank'>
              <CgRead /> <span>View full documentations</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const onPathParamChange = (obj) => {
    setPathParamId(obj.target.name);
  };

  const canShowResponseSampleTab = (reqType) => {
    if (appId !== 'chatgpt') {
      return true;
    }
    if (reqType === 'post') {
      return true;
    }
    return false;
  };

  const canShowEndPointUrl = (appId, reqType) => {
    if (appId === 'chatgpt' && reqType === 'get') {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className='endpoint-key play-btn' onClick={() => setVisible(true)}>
        <AiOutlinePlaySquare />
        <span>Use this API</span>
      </div>
      <Modal
        title={<ModalHead />}
        centered
        className='playground-modal'
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width='95%'
        bodyStyle={{ minHeight: '85vh' }}
        footer={null}
        keyboard={false}
      >
        <h3>
          <b>List of Endpoints</b>
        </h3>
        <Tabs
          onChange={() => {
            setActiveTab('1');
            setBodyPayload(null);
          }}
          tabPosition='left'
          className='tab-control'
          // style={{
          //   maxHeight: 600,
          //   overflowY: "scroll",
          // }}
        >
          {apiMetadata.map((obj, key) => {
            const { parameters } = obj.meta;
            const { reqType } = obj;
            const { keys } = apiMetaData;

            const isAPIKeyEnabled = keys[reqType] ? true : false;

            const requiredParams = parameters.filter(
              (item) => item.required && item.in === 'query',
            );
            const requiredPathParams = parameters.filter(
              (item) => item.required && item.in === 'path',
            );
            const optionalParams = parameters.filter(
              (item) => !item.required && item.in === 'query',
            );
            // const formParams = parameters.filter(item => item.required && item.in === 'formData')
            const bodyParam = parameters.filter((item) => item.in === 'body');

            const promptParam = parameters.filter((item) => item.in === 'prompt');

            return (
              <TabPane tab={<TabTitle title={obj.meta.summary} obj={obj} />} key={key}>
                <Row gutter={16} className='tab-content'>
                  <Col span={12} className='gutter-row tab-content-left'>
                    <Form
                      name='basic'
                      initialValues={{
                        reqMeta: [`${baseURLForEndpoint}${obj.endpoint}`, obj.reqType],
                        bodyObj:
                          bodyParam[0] !== undefined
                            ? appId === 'xml_to_json'
                              ? bodyParam[0].sampleData
                              : JSON.stringify(bodyParam[0].sampleData, null, 2)
                            : {},
                      }}
                      onFinish={onFinish}
                    >
                      <Row className='meta-des'>
                        <Col span={12}>
                          <h3
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            {obj.meta.summary}
                          </h3>
                        </Col>

                        <Col span={12}>
                          <Button
                            type='primary'
                            style={{
                              marginLeft: 'auto',
                              fontSize: 13,
                              fontWeight: 400,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            htmlType='submit'
                            icon={
                              <RiMouseLine
                                style={{
                                  marginRight: 5,
                                }}
                              />
                            }
                            loading={makingHTTPcall}
                          >
                            Click & Test API
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <p>{obj.meta.description}</p>
                        </Col>
                      </Row>

                      <div className='api-code-snippit'>
                        {canShowEndPointUrl(appId, reqType) && (
                          <div
                            className='endpoint'
                            style={{ display: 'flex', marginBottom: '10px' }}
                          >
                            <div className='endpoint-key label'>
                              <span>{obj.reqType.toUpperCase()}</span>
                              <RiArrowRightLine />
                            </div>
                            <div
                              className='endpoint-key endpoint-url'
                              style={{
                                overflow: 'hidden',
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {`${baseURLForEndpoint}${obj.endpoint}`}
                            </div>
                          </div>
                        )}

                        <Form.Item
                          name='reqMeta'
                          style={{
                            display: 'none',
                          }}
                        >
                          <Input placeholder='Value' />
                        </Form.Item>
                        <Form.Item
                          name='bodyObj'
                          style={{
                            display: 'none',
                          }}
                        >
                          <Input placeholder='Value' />
                        </Form.Item>

                        <Collapse activeKey={activeTab} onChange={changeTab}>
                          {requiredParams.length && (
                            <Panel
                              header={
                                <div
                                  style={{
                                    color: '#000',
                                  }}
                                >
                                  Params{' '}
                                  <span
                                    style={{
                                      fontSize: 11,
                                      color: '#cf1322',
                                      fontWeight: 300,
                                    }}
                                  >
                                    - Required
                                  </span>
                                </div>
                              }
                              key='1'
                              // style={{
                              //   maxHeight: 350,
                              //   overflowY: "scroll",
                              // }}
                            >
                              {requiredParams.map((paramValue, key1) => (
                                <div key={key1}>
                                  <Form.Item
                                    className='two-column'
                                    label={paramValue.name}
                                    name={paramValue.name}
                                    key={key}
                                    validateMessages=''
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    {paramValue.enum && Array.isArray(paramValue.enum) ? (
                                      <Select
                                        placeholder='Select a location'
                                      >
                                        {paramValue.enum.map((option) => (
                                          <Select.Option key={option} value={option}>
                                            {option}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    ) : (
                                      <Input
                                        placeholder="Value"
                                        style={{
                                          borderRadius: 0,
                                          padding: '4px 4px 5px 4px',
                                        }}
                                      />
                                    )}
                                  </Form.Item>
                                  <p
                                    className='extra'
                                    dangerouslySetInnerHTML={{
                                      __html: paramValue.description,
                                    }}
                                  />
                                </div>
                              ))}

                              {isAPIKeyEnabled && (
                                <div key='apiKeyRequired'>
                                  <Form.Item
                                    className='two-column'
                                    label='api_key'
                                    name='api_key'
                                    key={key}
                                    validateMessages=''
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder='API key'
                                      style={{
                                        borderRadius: 0,
                                        padding: '4px 4px 5px 4px',
                                      }}
                                    />
                                  </Form.Item>
                                  <p
                                    className='extra'
                                    dangerouslySetInnerHTML={{
                                      __html: 'NoCodeAPI api key from secure option',
                                    }}
                                  />
                                </div>
                              )}
                            </Panel>
                          )}

                          {requiredPathParams.length && (
                            <Panel
                              header={
                                <div
                                  style={{
                                    color: '#000',
                                  }}
                                >
                                  Path Params{' '}
                                  <span
                                    style={{
                                      fontSize: 12,
                                    }}
                                  >
                                    - Required
                                  </span>
                                </div>
                              }
                              key='2'
                              style={{
                                maxHeight: 350,
                                overflowY: 'scroll',
                              }}
                            >
                              {requiredPathParams.map((paramValue, key1) => (
                                <div key={key1}>
                                  <Form.Item
                                    className='two-column'
                                    label={paramValue.name}
                                    name='pathParam'
                                    key={key}
                                    validateMessages=''
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder='Value'
                                      name={paramValue.name}
                                      onChange={onPathParamChange}
                                    />
                                  </Form.Item>
                                  <p
                                    className='extra'
                                    dangerouslySetInnerHTML={{
                                      __html: paramValue.description,
                                    }}
                                  />
                                </div>
                              ))}
                            </Panel>
                          )}

                          {bodyParam.length > 0 && (
                            <Panel
                              header={
                                <div
                                  style={{
                                    color: '#000',
                                  }}
                                >
                                  Body{' '}
                                  <span
                                    style={{
                                      fontSize: 12,
                                      color: '#cf1322',
                                      fontWeight: 300,
                                    }}
                                  >
                                    - Required
                                  </span>
                                </div>
                              }
                              key='3'
                            >
                              <Editor
                                width='800'
                                height='40vh'
                                language='json'
                                theme='vs'
                                value={
                                  bodyParam[0] !== undefined
                                    ? appId === 'xml_to_json'
                                      ? bodyParam[0].sampleData
                                      : JSON.stringify(bodyParam[0].sampleData, null, 2)
                                    : {}
                                }
                                options={{
                                  selectOnLineNumbers: true,
                                  minimap: {
                                    enabled: false,
                                  },
                                }}
                                onChange={handleEditorChange}
                              />
                              <p className='extra'>{bodyParam[0].description}</p>
                            </Panel>
                          )}
                          {optionalParams.length > 0 && (
                            <Panel
                              header={
                                <div
                                  style={{
                                    color: '#000',
                                  }}
                                >
                                  Params{' '}
                                  <span
                                    style={{
                                      fontSize: 12,
                                    }}
                                  >
                                    - Optional
                                  </span>
                                </div>
                              }
                              key='4'
                            >
                              {optionalParams.map((paramValue, key2) => (
                                <div key={key2}>
                                  <Form.Item
                                    className='two-column'
                                    label={paramValue.name}
                                    name={paramValue.name}
                                    key={key}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder='Value'
                                      style={{
                                        borderRadius: 0,
                                        padding: '4px 4px 5px 4px',
                                      }}
                                    />
                                  </Form.Item>
                                  <p
                                    className='extra'
                                    dangerouslySetInnerHTML={{
                                      __html: paramValue.description,
                                    }}
                                  />
                                </div>
                              ))}
                            </Panel>
                          )}

                          {promptParam.length && (
                            <>
                              <Panel
                                header={
                                  <div
                                    style={{
                                      color: '#000',
                                    }}
                                  >
                                    Generate the prompt to send to chat gpt api.
                                  </div>
                                }
                                key='1'
                              >
                                <Form.Item className='mt-4'>
                                  <label className='text-gray-600 font-medium text-md'>
                                    Type of template
                                  </label>
                                  <Select
                                    className={'mt-2'}
                                    placeholder='Type of template'
                                    onChange={(value) => {
                                      setTemplate(value);
                                    }}
                                    options={templates}
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  />
                                </Form.Item>
                                {template && componentHashMap[template]}
                                {template && componentHashMap['add']}
                              </Panel>
                            </>
                          )}
                        </Collapse>
                      </div>
                    </Form>
                  </Col>

                  <Col span={12} className='gutter-row tab-content-right'>
                    <h3>
                      <b>List of Samples</b>
                    </h3>
                    <div className='result'>
                      <Tabs activeKey={activeKeyResponsePane} onChange={changeResponseTab}>
                        {canShowResponseSampleTab(reqType) ? (
                          <>
                            <Tabs.TabPane
                              tab={
                                <div
                                  style={{
                                    color: '#000',
                                  }}
                                >
                                  Response
                                </div>
                              }
                              key='4'
                            >
                              {endpointURL !== '' && (
                                <div>
                                  <a onClick={() => triggerCopy(endpointURL)}>
                                    <CopyOutlined /> Copy this endpoint{' '}
                                    {(appId === 'pdf' ||
                                      (appId === 'screen' &&
                                        endpointURL.indexOf('screenshots') === -1)) &&
                                      `& open into browser`}
                                  </a>
                                  <Alert message={endpointURL} type={reqStatusType} showIcon />
                                </div>
                              )}
                              <br />
                              {appId === 'pdf' ||
                              (appId === 'screen' && endpointURL.indexOf('screenshots') === -1) ? (
                                ''
                              ) : (
                                <CodeSnippet
                                  code={responseResult}
                                  token={token}
                                  language='json'
                                  id='apiResult'
                                  readOnly={false}
                                />
                              )}
                            </Tabs.TabPane>
                            <Tabs.TabPane
                              tab={
                                <div
                                  style={{
                                    color: '#000',
                                  }}
                                >
                                  Code Snippet
                                </div>
                              }
                              key='5'
                            >
                              <CodeBlock
                                type={obj.reqType}
                                url={
                                  endpointURL === ''
                                    ? `${baseURLForEndpoint}${obj.endpoint}`
                                    : endpointURL
                                }
                                token={token}
                                params={endpointURL === '' ? getQueryParam(parameters) : {}}
                                formData={getFormData(parameters)}
                                body={getBodyParam(parameters)}
                              />
                            </Tabs.TabPane>
                          </>
                        ) : (
                          <Tabs.TabPane
                            tab={
                              <div
                                style={{
                                  color: '#000',
                                }}
                              >
                                Output
                              </div>
                            }
                            key='4'
                          >
                            <CodeSnippet
                              code={responseResult}
                              token={token}
                              readOnly={appId !== 'chatgpt'}
                              language='json'
                              id='apiResult'
                            />
                          </Tabs.TabPane>
                        )}
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            );
          })}
        </Tabs>
      </Modal>
    </>
  );
};

export default APIPlayground;
