const generateLocationEnum = () => [
  'africa-south1-a', 'africa-south1-b', 'africa-south1-c',
  'asia-east1-a', 'asia-east1-b', 'asia-east1-c',
  'asia-east2-a', 'asia-east2-b', 'asia-east2-c',
  'asia-northeast1-a', 'asia-northeast1-b', 'asia-northeast1-c',
  'asia-northeast2-a', 'asia-northeast2-b', 'asia-northeast2-c',
  'asia-northeast3-a', 'asia-northeast3-b', 'asia-northeast3-c',
  'asia-south1-a', 'asia-south1-b', 'asia-south1-c',
  'asia-south2-a', 'asia-south2-b', 'asia-south2-c',
  'asia-southeast1-a', 'asia-southeast1-b', 'asia-southeast1-c',
  'asia-southeast2-a', 'asia-southeast2-b', 'asia-southeast2-c',
  'australia-southeast1-a', 'australia-southeast1-b', 'australia-southeast1-c',
  'australia-southeast2-a', 'australia-southeast2-b', 'australia-southeast2-c',
  'europe-central12-a', 'europe-central12-b', 'europe-central12-c',
  'europe-north1-a', 'europe-north1-b', 'europe-north1-c',
  'europe-southwest1-a', 'europe-southwest1-b', 'europe-southwest1-c',
  'europe-west1-b', 'europe-west1-c', 'europe-west1-d',
  'europe-west2-a', 'europe-west2-b', 'europe-west2-c',
  'europe-west3-a', 'europe-west3-b', 'europe-west3-c',
  'europe-west4-a', 'europe-west4-b', 'europe-west4-c',
  'europe-west6-a', 'europe-west6-b', 'europe-west6-c',
  'europe-west8-a', 'europe-west8-b', 'europe-west8-c',
  'europe-west9-a', 'europe-west9-b', 'europe-west9-c',
  'europe-west10-a', 'europe-west10-b', 'europe-west10-c',
  'europe-west12-a', 'europe-west12-b', 'europe-west12-c',
  'me-central1-a', 'me-central1-b', 'me-central1-c',
  'me-central2-a', 'me-central2-b', 'me-central2-c',
  'me-west1-a', 'me-west1-b', 'me-west1-c',
  'us-central1-a', 'us-central1-b', 'us-central1-c', 'us-central1-f',
  'us-east1-b', 'us-east1-c', 'us-east1-d',
  'us-east4-a', 'us-east4-b', 'us-east4-c',
  'us-east5-a', 'us-east5-b', 'us-east5-c',
  'northamerica-northeast1-a', 'northamerica-northeast1-b', 'northamerica-northeast1-c',
  'northamerica-northeast2-a', 'northamerica-northeast2-b', 'northamerica-northeast2-c',
  'us-south1-a', 'us-south1-b', 'us-south1-c',
  'us-west1-a', 'us-west1-b', 'us-west1-c',
  'us-west2-a', 'us-west2-b', 'us-west2-c',
  'us-west3-a', 'us-west3-b', 'us-west3-c',
  'us-west4-a', 'us-west4-b', 'us-west4-c',
  'southamerica-east1-a', 'southamerica-east1-b', 'southamerica-east1-c',
  'southamerica-west1-a', 'southamerica-west1-b', 'southamerica-west1-c',
];

const commonParams = {
  projectId: {
    name: 'projectId',
    in: 'query',
    description: 'ID of your Google Cloud project',
    required: true,
    type: 'string',
  },
  instanceId: {
    name: 'instanceId',
    in: 'query',
    description: 'ID of the Bigtable instance',
    required: true,
    type: 'string',
  },
  tableId: {
    name: 'tableId',
    in: 'query',
    description: 'ID of the table to be created',
    required: true,
    type: 'string'
  },
  clusterId: {
    name: 'clusterId',
    in: 'query',
    description: 'ID of the cluster',
    required: true,
    type: 'string',
  },
  location: {
    name: 'location',
    in: 'query',
    description: 'Location for the instance/cluster',
    required: true,
    type: 'string',
    enum: generateLocationEnum(),
  },
  columnFamilyId: {
    name: 'columnFamilyId',
    in: 'query',
    description: 'The column family name',
    required: true,
    type: 'string',
  },
  rowKey: {
    name: 'rowKey',
    in: 'query',
    description: 'The key of the row to retrieve',
    required: true,
  },
  storage: {
    name: 'storage',
    in: 'query',
    description: 'Storage type ("SSD" or "HDD")',
    required: true,
    type: 'string',
    enum: ['SSD', 'HDD'],
  },
  nodes: {
    name: 'nodes',
    in: 'query',
    description: 'Number of nodes',
    required: true,
    type: 'integer',
  },
};

exports.obj = function (username, appId, token) {
  const schemaObj = {};

  const createEndpoint = (method, path,  summary, description, parameters, responses) => ({
    [path]: {
      [method]: {
        tags:  ['Google Bigtable'],
        summary,
        description,
        produces: ['application/json'],
        parameters,
        responses,
      },
    },
  });

  Object.assign(schemaObj, createEndpoint('post', `/${username}/${appId}/${token}/createInstance`,
    'Create instance',
    'Creates a Bigtable instance in the specified project.',
    [
      commonParams.projectId,
      commonParams.instanceId,
      commonParams.clusterId,
      commonParams.location,
      commonParams.storage,
      commonParams.nodes
    ],
    {
      201: { description: 'Instance created successfully' },
      400: { description: 'Invalid data' },
      500: { description: 'Server error' },
    },
  ));

  Object.assign(schemaObj, createEndpoint('delete', `/${username}/${appId}/${token}/deleteInstance`,
    'Delete instance',
    'Deletes a Bigtable instance in the specified project.',
    [
      commonParams.projectId,
      commonParams.instanceId
    ],
    {
      200: { description: 'Instance deleted successfully' },
      400: { description: 'Missing fields' },
      404: { description: 'Not found' },
    },
  ));

  Object.assign(schemaObj, createEndpoint('post', `/${username}/${appId}/${token}/createCluster`,
    'Create cluster',
    'Creates a cluster in the specified Bigtable instance.',
    [
      commonParams.projectId,
      commonParams.instanceId,
      commonParams.clusterId,
      commonParams.location,
      commonParams.storage,
      commonParams.nodes
    ],
    {
      201: { description: 'Cluster created successfully' },
      400: { description: 'Invalid data' },
      500: { description: 'Server error' },
    },
  ));
  Object.assign(schemaObj, createEndpoint('delete', `/${username}/${appId}/${token}/deleteCluster`,
    'Delete cluster',
    'Deletes a specific cluster within a Bigtable instance in the specified project.',
    [
      commonParams.projectId,
      commonParams.instanceId,
      commonParams.clusterId
    ],
    {
      201: { description: 'Cluster deleted successfully' },
      400: { description: 'Bad Request: Missing projectId, instanceId, or clusterId' },
      404: { description: 'Not Found: Cluster does not exist'},
      500: { description: 'Server error' },
    },
  ));
  Object.assign(schemaObj, createEndpoint('get', `/${username}/${appId}/${token}/listTables`,
    'List tables',
    'Lists all tables in the specified Bigtable instance.',
    [
      commonParams.projectId,
      commonParams.instanceId,
    ],
    {
      200: {
        description: 'Successful operation',
        schema: {
          type: 'object',
          properties: {
            tables: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
          },
        },
      },
      400: { description: 'Bad Request: Missing instanceId' },
      500: { description: 'Server Error: Unable to list tables' },
    },
  ));
  Object.assign(schemaObj, createEndpoint('post', `/${username}/${appId}/${token}/createTable`,
    'Create table',
    'Creates a table in the specified Bigtable instance.',
    [
      commonParams.projectId,
      commonParams.instanceId,
      commonParams.tableId,
      commonParams.columnFamilyId,
    ],
    {
      200: { description: 'Successful table creation' },
      400: { description: 'Bad Request: Missing tableId or instanceId or projectID' },
      500: { description: 'Server Error: Unable to create the table' },
    },
  ));
  Object.assign(schemaObj, createEndpoint('delete', `/${username}/${appId}/${token}/deleteTable`,
    'Delete table',
    'Deletes a table in the specified Bigtable instance.',
    [
      commonParams.projectId,
      commonParams.instanceId,
      commonParams.tableId,
    ],
    {
      200: { description: 'Successful table deleting' },
      400: { description: 'Bad Request: Missing tableId or instanceId or projectID' },
      500: { description: 'Server Error: Unable to delete the table' },
    },
  ));
  Object.assign(schemaObj, createEndpoint('get', `/${username}/${appId}/${token}/readRow`,
    'Read row',
    'Retrieves a specific row from a table',
    [
      commonParams.projectId,
      commonParams.instanceId,
      commonParams.tableId,
      commonParams.rowKey,
    ],
    {
      200: { description: 'Successful operation' },
    },
  ));
  Object.assign(schemaObj, createEndpoint('post', `/${username}/${appId}/${token}/insertRow`,
    'Insert row',
    'Inserts new rows with data into a specified Bigtable table.',
    [
      commonParams.projectId,
      commonParams.instanceId,
      commonParams.tableId,
      {
        name: 'body',
        in: 'body',
        description: 'Request body to insert rows into Bigtable',
        required: true,
        sampleData: {
          rows: [
            {
              key: "rowKey1",
              columnFamily: "cf1",
              columnName: "columnName1",
              value: "value1",
            },
            {
              key: "rowKey2",
              columnFamily: "cf1",
              columnName: "columnName2",
              value: "value1",
            },
          ]
        }
      },
    ],
    {
      201: { description: 'Rows inserted successfully.' },
      400: { description: 'Bad Request: Missing required parameters.' },
      500: { description: 'Server Error: Unable to insert rows.' },
    },
  ));


  return schemaObj;
};
